
<template>
  <div class="teacherWorks">
    <div class="cont" v-if="list.length > 0">
      <el-row
        v-for="(li, i) in list"
        :key="i"
        type="flex"
        justify="space-between"
        align="top"
      >
        <el-col>
          <el-image
            :src="li.CoverImg"
            :preview-src-list="images"
            @click="handleImagePreview(li)"
          >
          </el-image>
          <!-- <img v-lazy="li.CoverImg" alt="" @click="handleImagePreview(li)" /> -->
          <span @click="handleTo(li)"
            >{{ li.Title }}(共{{ li.ImgCount }}张)</span
          >
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="24">
          <no-data></no-data>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { commonQueryTeacherProduce } from "@/api/home";
import noData from "@/components/noData";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      contactsIndex: 0,
      tabs: [],
      loading: false,
      finished: false,
      images: [],
    };
  },
  components: {
    noData,
  },
  created() {
    this.route = this.$route.query;
    this.init();
  },
  mounted() {},
  methods: {
    // 预览
    handleImagePreview(obj) {
      this.images = obj.images;
    },
    handleTo(obj) {
      let path = "/H5/teacherWorksDetails";
      let query = {
        id: obj.Id,
      };
      this.$router.push({ path, query });
    },
    async init() {
      let parm = "?teacherId=" + this.route.teacherId;
      const res = await commonQueryTeacherProduce(parm);
      if (res.success === true) {
        this.list = res.response;
        this.list.forEach((item) => {
          item.images = this.common.getImgSrc(item.Content);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.teacherWorks {
  background-color: #fff;
  .cont {
    padding: 15px;
    .el-row {
      margin: 10px 0;
      .el-col {
        position: relative;
        .el-image {
          width: 100%;
          min-height: 270px;
          height: 100%;
        }
        img {
          width: 100%;
          min-height: 270px;
          height: auto;
        }
        span {
          position: absolute;
          bottom: 0;
          right: 0;
          display: inline-block;
          min-width: 70px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          padding: 0 10px;
          background-color: #2b6efb;
        }
      }
    }
  }
}

/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
</style>